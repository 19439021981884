import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import database from '../assets/resources/enums/database.json'

export const AuthContext = React.createContext();

export const initialDataHotels = {
  "accommodation": [],
  "destinationId": "",
  "checkIn": moment(new Date()).add(2, "days").format("YYYY-MM-DD"),
  "checkOut": moment(new Date()).add(6, "days").format("YYYY-MM-DD"),
  "language": "EN",
  "currencyCode": database.bookingRq.currencyCode,
  "countryCode": database.bookingRq.countryCode,
  "itaCode": 0,
  "applicationId": database.application.vivelo,
  "contactId": "",
  "isExtension": false
}

const currentDate = moment(new Date()).add(2, "days");
const hotelSearchDateLocal = currentDate.isAfter(JSON.parse(localStorage.getItem('hotelSearch'))?.checkIn) ?
  {
    ...JSON.parse(localStorage.getItem('hotelSearch')),
    checkIn: moment(new Date()).add(2, "days").format("YYYY-MM-DD"),
    checkOut: moment(new Date()).add(6, "days").format("YYYY-MM-DD")
  } : JSON.parse(localStorage.getItem('hotelSearch'))

export const initialDataPackage = {
  "searchText": "",
  "searchKeys": [],
  "language": "",
  "countryCode": database.bookingRq.countryCode,
  "currencyCode": database.bookingRq.currencyCode,
  "applicationId": database.application.vivelo,
  "itaCode": 0
}


export const initialDataRQ = {
  bookingId: 0,
  contactId: 0,
  itaCode: 0,
  countryCode: database.bookingRq.countryCode,
  emailVoucher: "",
  confirmEmailVoucher: "",
  emergencyName: "",
  emergencyCountryCode: "",
  emergencyPhoneType: "",
  emergencyPhone: "",
  language: "",
  applicationId: database.application.vivelo,
  createdBy: "",
  bookingDetail: [{
    productId: database.bookingRq.productId,
    supplierId: "",
    bookingSourceId: database.bookingRq.bookingSourceId,
    createdBy: "",
    reserved: database.bookingRq.reserved,
    payment: {},
    currencyCode: database.bookingRq.currencyCode,
    detail: {
      currencyCode: database.bookingRq.currencyCode,
      // hotelId      : "",
      // checkIn      : "",
      // checkOut     : "",
      bookingHotelRoom: [{
        keys: [],
        bookingHotelPassenger: [],
        name: "",
        roomName: "",
        valueNeto: "",
        supplierId: ""
      }]
    },
    value: "",
    fee: "",
    tax: 0,
    merchant: "",
    feecustomized: "",
    // discountIn    : 0,
    // discount: 0,
    othervalue: 0,
    total: "",
    wayToPayId: "",
    // planId        : "",
    // benefitId     : ""
  }]
}

if (localStorage.getItem('hotelSearch')) {

  if (new Date(JSON.parse(localStorage.getItem('hotelSearch')).checkIn).toISOString().slice(0, 10) < new Date().toISOString(0, 10)) {
    localStorage.removeItem('hotelSearch')
  }
}

const initialPaymentCreditCard = {
  "creditCardTypeId": "",
  "cardNumber": "",
  "securityCode": "",
  "expirationMonth": "",
  "expirationYear": "",
  "documentNumber": "",
  "name": "",
  "lastName": "",
  "address": "",
  "phone": "",
  "zipCode": "",
  "email": "",
  "cityId": 0,
  "cityName": "",
  "installmentNumber": 0,
  "amount": "",
  "currencyCode": database.bookingRq.currencyCode,
  "xid": null,
  "cavv": null,
  "eci": null,
  "transactionId": null
};

const initialPaymentWallet = {
  "walletId": "",
  "amount": "",
  "userName": "",
  "charge": true,
  "accountTypeId": 1
}

const initialPaymentTransfer = {
  accountId: "",
  amount: ""
}

const initialGetPurchases = {
  "status": "",
  "itaCode": "",
  "type": "",
  "start_date": moment(new Date()).format("YYYY-MM-DD"),
  "end_date": moment(new Date()).format("YYYY-MM-DD")
}

const AuthProvider = (props) => {
  let history = useHistory();
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('userInfo') ? true : false);
  const [user, setUser] = useState(localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {});
  const [hotelSearch, setHotelSearch] = useState(localStorage.getItem('hotelSearch') ? hotelSearchDateLocal : initialDataHotels);
  const [hotelData, setHotelData] = useState(localStorage.getItem('hotelData') ? JSON.parse(localStorage.getItem('hotelData')) : undefined);
  const [hotelDataPopular, setHotelDataPopular] = useState([]);
  const [imagePpal, SetImagePpal] = useState("");
  const [passengers, setPassengers] = useState(sessionStorage.getItem('passengers') ? JSON.parse(sessionStorage.getItem('passengers')) : [])
  const [hotelSelected, setHotelSelected] = useState(sessionStorage.getItem('hotelSelected') ? JSON.parse(sessionStorage.getItem('hotelSelected')) : {});
  const [roomSelected, setRoomSelected] = useState(sessionStorage.getItem('roomSelected') ? JSON.parse(sessionStorage.getItem('roomSelected')) : {})
  const [bookingRq, setBookingRq] = useState(localStorage.getItem('bookingRq') ? JSON.parse(localStorage.getItem('bookingRq')) : initialDataRQ)
  const [paymentCreditCard, setPaymentCreditCard] = useState(initialPaymentCreditCard);
  const [paymentWallet, setPaymentWallet] = useState(initialPaymentWallet);
  const [paymentTransfer, setPaymentTransfer] = useState(initialPaymentTransfer);
  const [dataPassengers, setDataPassengers] = useState([]);
  const [aTypeFilter, setATypeFilter] = useState(localStorage.getItem('aTypeFilter') ? JSON.parse(localStorage.getItem('aTypeFilter')) : [])
  const [responseBooking, setResponseBooking] = useState([]);
  const [entrySelected, setEntrySelected] = useState(localStorage.getItem('entrySelected') ? JSON.parse(localStorage.getItem('entrySelected')) : [])
  const [packageData, setPackageData] = useState(localStorage.getItem('packageData') ? JSON.parse(localStorage.getItem('packageData')) : undefined)
  const [packageSearch, setPackageSearch] = useState({});
  const [pricesFilter, setPricesFilter] = useState(localStorage.getItem('pricesFilter') ? JSON.parse(localStorage.getItem('pricesFilter')) : [])
  const [selectedOptionBenefit, setSelectedOptionBenefit] = useState(localStorage.getItem("optionBenefit") ? parseInt(localStorage.getItem("optionBenefit")) : 5)
  const [rqPurchases, setRqPurchases] = useState(initialGetPurchases)
  const [rqInsurance, setRqInsurance] = useState()
  const [rqThingsToDo, setRqThingsToDo] = useState()
  const [categoryId, setCategoryId] = useState(0)
  const [listCategories, setListCategories] = useState([])
  const [keyThingsToDo, setKeyThingsToDo] = useState()
  const [planId, setPlanId] = useState()
  const [selectedActivity, setSelectedActivity] = useState()
  const [selectedGroup, setSelectedGroup] = useState()

  const [token, setToken] = useState("");

  const validateBooking = useMemo(() => ({
    _passengers: () => {

      let empty = 0;
      for (var i in dataPassengers) {
        const passengerProperties = dataPassengers[i];
        for (var j in passengerProperties) {
          if (passengerProperties[j] === "") {
            if (i > 0) {
              if (j === "name" || j === "lastname") {
                empty++
              }
            }
            else {
              empty++
            }
          }
        }
      }
      if (empty > 0) {
        return false
      }
      else {
        return true
      }
    },
    _payment: () => {
      let empty = 0;
      const { payment } = bookingRq.bookingDetail[0];
      for (var i in payment) {
        if (payment[i] === "" || payment[i] === null) {
          if (i !== "cityName" && i !== "xid" && i !== "cavv" && i !== "eci" && i !== "transactionId" && i !== "amount") {
            empty++
          }
        }
        else if (i === "email") {
          if (!payment[i].includes("@") && !payment[i].includes(".")) {
            empty++
          }
        }
      }

      if (empty > 0) {
        if (bookingRq.bookingDetail[0].payment.amount === 0) {
          return true
        }
        return false;
      }
      else {
        return true
      }
    },
    _booking: () => {
      const { bookingDetail } = bookingRq;
      const { detail } = bookingDetail;
      let empty = 0;

      for (var k in bookingRq) {
        if (bookingRq[k] === "" || bookingRq[k] === null) {
          empty++
        }
        else if (k === "confirmEmailVoucher" || k === "emailVoucher") {
          if (!bookingRq[k].includes("@") && !bookingRq[k].includes(".")) {
            empty++
          }
        }
      }

      for (var i in bookingDetail) {
        if (bookingDetail[i] === "" || bookingDetail[i] === null) {
          empty++
        }
      }

      for (var j in detail) {
        if (detail[j] === "" || detail[j] === null) {
          empty++
        }
      }

      if (empty > 0) {
        return false;
      }
      else {
        return true
      }

    }

  }), [dataPassengers, bookingRq])

  const signIn = (data) => {
    localStorage.setItem('userInfo', JSON.stringify(data));
    setUser(data);
    setLoggedIn(true);
    history.push("/benefits");
  };

  const signUp = (data) => {
    localStorage.setItem('userInfo', JSON.stringify(data));
    setUser(data);
    setLoggedIn(true);
    history.goBack();
  };

  const logOut = () => {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('hotelSearch');

    localStorage.removeItem('roomSelected');
    localStorage.removeItem('hotelSelected');
    localStorage.removeItem('passengers');
    localStorage.removeItem('hotelData');
    localStorage.removeItem('aTypeFilter');
    localStorage.removeItem('bookingRq');
    localStorage.removeItem("optionBenefit")


    setUser({});
    setHotelSearch(initialDataHotels)
    setLoggedIn(false);
    SetImagePpal("");
    if (history.location.pathname === '/') {
      history.go()
    }
    else {
      history.push('/')
    }
  };

  const cleanData = () => {
    localStorage.removeItem('roomSelected');
    localStorage.removeItem('passengers');
    localStorage.removeItem('hotelSelected');
    localStorage.removeItem('hotelData');
    localStorage.removeItem('aTypeFilter');
    localStorage.removeItem('bookingRq');
    localStorage.removeItem('entrySelected');

    setPassengers([])
    setRoomSelected({})
    setPaymentCreditCard(initialPaymentCreditCard);
    setPaymentWallet(initialPaymentWallet);
    setBookingRq(initialDataRQ)
    setResponseBooking([])
    setDataPassengers([])
    setEntrySelected([])
  }

  const _cleanData = ({ isPackage }) => {
    setPaymentCreditCard(initialPaymentCreditCard);
    setPaymentWallet(initialPaymentWallet);
    if (!isPackage)
      setBookingRq(initialDataRQ)
    setPassengers([])
    setDataPassengers([])
  }

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        logOut,
        signIn,
        signUp,
        user,
        token, setToken,
        hotelSearch, setHotelSearch,
        hotelData, setHotelData,
        imagePpal, SetImagePpal,
        hotelDataPopular, setHotelDataPopular,
        passengers, setPassengers,
        bookingRq, setBookingRq,
        hotelSelected, setHotelSelected,
        roomSelected, setRoomSelected,
        paymentCreditCard, setPaymentCreditCard,
        paymentWallet, setPaymentWallet,
        dataPassengers, setDataPassengers,
        aTypeFilter, setATypeFilter,
        validateBooking, cleanData, _cleanData,
        responseBooking, setResponseBooking,
        paymentTransfer, setPaymentTransfer,
        entrySelected, setEntrySelected,
        packageData, setPackageData,
        packageSearch, setPackageSearch,
        pricesFilter, setPricesFilter,
        selectedOptionBenefit, setSelectedOptionBenefit,
        rqPurchases, setRqPurchases,
        rqInsurance, setRqInsurance,
        rqThingsToDo, setRqThingsToDo,
        keyThingsToDo, setKeyThingsToDo,
        categoryId, setCategoryId,
        listCategories, setListCategories,
        planId, setPlanId,
        selectedActivity, setSelectedActivity,
        selectedGroup, setSelectedGroup
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
